import React, {useState} from 'react';
import 'App.scss';
import AppHeader from 'components/AppHeader';
import NavMenu, {Pages} from 'components/NavMenu';
import Cv from 'components/Cv/Cv';
import About from 'components/About/About';
import Contact from 'components/Contact/Contact';
import Page from 'components/Page/Page';
import {ReactComponent as Power} from 'images/power.svg';

function App() {
  const [currentPage, setCurrentPage] = useState<Pages>(Pages.home);
  const [terminalOpen, setTerminalOpen] = useState<boolean>(false);

  const [variant] = useState<number>(Math.floor(Math.random() * Math.floor(7)));

  return (
    <>
      {currentPage === Pages.home && <AppHeader variant={variant}
      terminalControls={{open: terminalOpen, setOpen: setTerminalOpen}} />}
      {currentPage === Pages.cv && <Page><Cv /></Page>}
      {currentPage === Pages.about && <Page><About /></Page>}
      {currentPage === Pages.contact && <Page><Contact /></Page>}
      {!terminalOpen && <NavMenu currentPage={currentPage}
               onChange={setCurrentPage} />}
      {<Power className={`power ${terminalOpen ? 'on' : ''}`}
             onClick={() => {setTerminalOpen(!terminalOpen); setCurrentPage(Pages.home);}} />}
    </>
  );
}

export default App;

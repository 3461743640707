import React from 'react';
// import me from './me.jpeg';
import styles from './AppHeader.module.scss';
// import {ReactComponent as Power} from 'images/power.svg';

type Props = {
    variant: number;
    terminalControls: {open: boolean, setOpen: (open: boolean) => void};
};

const AppHeader: React.FC<Props> = (props: Props) => {
  const {terminalControls} = props;
  const toggleTerminal = () => {
    terminalControls.setOpen(!terminalControls.open);
  };
  return (
    <>
      <header className={`${styles.AppHeader} ${terminalControls.open ? styles.terminal : ''}`}>
         <div className={styles.inlinable} onClick={toggleTerminal}>
           {/*<div className={styles.AppLogo}>
            {variant===1 && <div className={styles.arrowUp} />}
            {variant===2 && <div className={styles.circle} />}
            {variant===3 && <div className={styles.arrowDown} />}
            {variant===4 && <div className={styles.rectangleVertical} />}
            {variant===5 && <div className={styles.rectangleHorizontal} />}
            {variant===6 && <div className={styles.square} />}
            <Power title="on/off" />
  </div>*/}
          <p className={styles.AppCaption}>
            hobbs@dev:~>
          </p>
        </div>
      </header>
    </>
  );
};

export default AppHeader;

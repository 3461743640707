import React from 'react';
import styles from './Page.module.scss';

type Props = {
    children: React.ReactNode;
};
const Page: React.FC<Props> = (props: Props) => {
    const {children} = props;
    return (
        <div className={styles.page}>
            {children}
        </div>
    );
}

export default Page;

import React from 'react';
// import me from 'me_small.jpeg';
import styles from './About.module.scss';

const About: React.FC = () => {
  return (<>
      <span className={styles.aboutContainer}>
        {/*<img src={me} className={styles.AppLogo} alt="profile" />*/}
        {/*<p>
          <span className={styles.parapraphHeader}>Location</span>
          <span className={styles.parapraphContent}>Based in Zürich. Can work remote.</span>
        </p>*/}
        <p>
          <span className={styles.parapraphHeader}>Values</span>
          <span className={styles.parapraphContent}>Usability, performance, optimization, reusability, simplicity, teamwork, mentoring,
          learning, responsive, solutions.</span>
        </p>
        <p>
        <span className={styles.parapraphHeader}>Techstack</span>
        <span className={styles.parapraphContent}>React, typescript, sass, react-router, i18next, yarn, jest, enzyme, redux, storybook,
          docker, ci/cd, gocd, axios.</span>
        </p>
        {/*<p>
        <span className={styles.parapraphHeader}>Interests</span>
        <span className={styles.parapraphContent}>vuejs, python, nodejs.</span>
        </p>*/}
      </span>
  </>);
};

export default About;

import React from 'react';
import styles from './NavMenu.module.scss';
// import {ReactComponent as Logo} from 'images/logo.svg';

export enum Pages {
    'home' = 'home',
    'cv' = 'cv',
    'about' = 'about',
    'contact' = 'contact'
};
type Props = {
    currentPage: Pages;
    onChange: (newPage: Pages) => void;
}

const NavMenu: React.FC<Props> = (props: Props) => {
    const {currentPage, onChange} = props;
  return (
      <div className={styles.nav}>
        {/*currentPage !== Pages.home &&
          <div onClick={() => onChange(Pages.home)}>
            <Logo />
          </div>
        */ }
        <div className={currentPage === Pages.cv ? styles.active : ''}
            onClick={() => onChange(Pages.cv)}>
          cv
        </div>
        <div className={currentPage === Pages.about ? styles.active : ''}
            onClick={() => onChange(Pages.about)}>
          about
        </div>
        <div className={currentPage === Pages.contact ? styles.active : ''}
            onClick={() => onChange(Pages.contact)}>
          contact
        </div>
      </div>
  );
}

export default NavMenu;

import React from 'react';
import styles from './CV.module.scss';

const Cv: React.FC = () => {
  return (<>
<span className={styles.aboutContainer}>
        <p className={styles.parapraphHeader}>
            Work Experience
        </p>
        <p>
            2015 - 2020
            Frontend Software Engineer
            Lead Dev
            React | ts
            Banking | Insurance
            AdNovum
        </p>
        <p className={styles.parapraphHeader}>
            Education
        </p>
        <p>
            2008 - 2014
            MSc Computer Science
            EPFL
        </p>
        <p className={styles.parapraphHeader}>
            Languages
        </p>
        <p>
            Native: English, French
            B2: German
            B1: Portuguese
        </p>
        </span>
  </>);
}

export default Cv;

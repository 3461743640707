import React from 'react';
import styles from './Contact.module.scss';

const Contact: React.FC = () => {
  return (<>
    {/*<p></p>
    <br />*/}
    <a href="mailTo:michael@hobbs.im"
      >
      <div className={styles.typewriter}>say hello</div>
    </a>
    {/*<br />
    <div><p className={styles.typewriterDelayed}>michael@hobbs.im</p></div>*/}
  </>);
}

export default Contact;
